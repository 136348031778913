import React, { useEffect, useState } from "react";
import goutteQuestion from "../assets/images/goutteQuestion.svg";
import { TbTriangleInvertedFilled } from "react-icons/tb";
import goutteQuestionRed from "../assets/images/goutteQuestionRed.svg";
import { Link } from "react-router-dom";
import { useUser } from "../Hook/useUser";
import { FaChevronRight } from "react-icons/fa6";
import useAxios from "../Hook/useAxios";
import Markdown from 'react-markdown'

function HomeQuestion() {
	const { setModalQuestionIsOpen, user } = useUser();
	const [faq, setFaq] = useState("");
	const linkAllQuestion =
		user === "parent"
			? "/Parents/QuestionsUtilisateurs"
			: "Jeunes/QuestionsUtilisateurs";
	const allFaq = useAxios("/testimonies?populate=*");

	useEffect(() => {
		const getData = () => {
			if (allFaq) {
				const faqItems = allFaq.data.data.map((item) => ({
					id: item.id,
					push_first: item.attributes.push_first,
					question: item.attributes.question,
					answer: item.attributes.answer,
					category: item.attributes.categories.data.map(
						(category) => ({
							name: category.attributes.name,
							visible: category.attributes.visible,
						})
					),
				}));
				const userInCat = user === "young" ? "Jeune" : "Parents";
				const filteredFaqItems = faqItems.filter(
					(faq) =>
						faq.push_first &&
						faq.category.find((cat) => cat.name === userInCat)
				);
				const randomIndex = Math.floor(
					Math.random() * filteredFaqItems.length
				);
				setFaq(filteredFaqItems[randomIndex]);
			}
		};
		getData();
	}, [allFaq, user]);

	const handleKeyDown = (event, search) => {
		if (event.key === "Enter") {
			event.preventDefault();
			setModalQuestionIsOpen({
				formModal: true,
				thanksModal: false,
			});
		}
	};

	return (
		<div
			className="homeQuestion"
			style={user === "parent" ? { backgroundColor: "#9129FF" } : {}}
		>
			<div className="homeQuestion__title-container">
				<div>
					<h2 className="homeQuestion__title-line1">
						On répond{" "}
							<img
							className="homeQuestion__goutte-img"
							src={goutteQuestion}
							alt=""
							aria-hidden="true"
						/>
					</h2>
					<h2 className="homeQuestion__title-line2">
					{user === "young" && (
						<i>à tes questions</i>
					)}
					{user !== "young" && (
						<i>à vos questions</i>
					)}
					</h2>
				</div>
				<div className="homeQuestion__button-container">
					<Link to={linkAllQuestion} className="focused">
						<p className="homeQuestion__showMore-button focused">
							Voir plus de questions{" "}
							<FaChevronRight className="partners-footer__chevron" />
						</p>
					</Link>
					{user === "young" && (
					<p
						onKeyDown={(e) => handleKeyDown(e)}
						tabIndex="0"
						className="homeQuestion__send-question-button"
						onClick={() =>
							setModalQuestionIsOpen({
								formModal: true,
								thanksModal: false,
							})
						}
					>
						J'envoie ma question à l'équipe
					</p>
					)}
				</div>
			</div>
			<div className="homeQuestion__question-container">
				<div className="homeQuestion__goutte-row">
					<div className="homeQuestion__goutte-container">
						<img
							className="homeQuestion__goutte-red-img"
							src={goutteQuestionRed}
							alt=""
							aria-hidden="true"
						/>
					</div>
				</div>
				<div className="homeQuestion__question-top">
					<p>{faq.question}</p>
				</div>
				<div className="homeQuestion__triangle-container">
					<TbTriangleInvertedFilled className="homeQuestion__triangle" />
				</div>
				<div className="homeQuestion__question-bot">
					<p><Markdown>{faq.answer}</Markdown></p>
				</div>
				<div className="homeQuestion__allFaq-button-container">
					<Link to={linkAllQuestion}>
						<button className="homeQuestion__allFaq-button">
							Voir d'autres questions/réponses{" "}
							<FaChevronRight className="partners-footer__chevron" />
						</button>
					</Link>
				</div>
			</div>
		</div>
	);
}

export default HomeQuestion;
