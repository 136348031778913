import React from "react";
import { useNavigate } from "react-router-dom";
import SearchBar from "./SearchBar";
import imgMobile from "../assets/images/bulle_home_mobile.svg";
import imgDestkop from "../assets/images/bulle_home_desktop.svg";
import ChoosePreferenceButton from "./ChoosePreferenceButton";
import useMobile from "../Hook/UseMobile";

function YoungHeader() {
	const navigate = useNavigate();
    const isMobile = useMobile();

	const goToResult = (value) => {
		navigate("/Jeunes/Resultats", { state: { userInput: value } });
	};

	const goToAllOnPeriodsWithTag = (value) => {
		navigate("/Jeunes/ToutSurLesRegles", { state: { tag: value} });
	};

	return (
		<div className="young-head">
			<div className="young-head__searchbar-container">
				<img className="young-head__img" src={isMobile ? imgMobile : imgDestkop} alt="Salut, ici on parle de règles sans gêne. Quels sujets t’intéressent ?" />
				<SearchBar />
                {!isMobile &&(
                    <div className="young-head__question-desktop-container">
                        <button tabIndex="0" className="young-head__question-desktop focused" onClick={() => goToResult("Douleurs")}>#douleurs</button>
                        <button tabIndex="0" className="young-head__question-desktop focused" onClick={() => goToResult("Sport")}>#sport</button>
                        <button tabIndex="0" className="young-head__question-desktop focused" onClick={() => goToResult("Tampon")}>#tampon</button>
                    </div>
                )}
			</div>
			{isMobile && (
				<div className="young-head__most-question-container">
					<button tabIndex="0" className="young-head__question-mobile focused" onClick={() => goToResult("Douleurs")}>#douleurs</button>
					<button tabIndex="0" className="young-head__question-mobile focused" onClick={() => goToResult("Sport")}>#sport</button>
					<button tabIndex="0" className="young-head__question-mobile focused" onClick={() => goToResult("Tampon")}>#tampon</button>
				</div>
			)}
			<div className="young-head__video-container">
				<iframe
					className="young-head__video"
					width="560"
					height="315"
					src="https://www.youtube.com/embed/PpnJh-LZFMw?si=cm0RSxhrMXK_5ybn"
					title="YouTube video player"
					allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
					allowFullScreen
				></iframe>
				<div className="young-head__video-desc-container">
					<p className="young-head__video-title">
					« Les règles, c’est sale » : un cliché vieux comme l’histoire
					</p>
					<div className="young-head__tag-list-container">
						<button type="button" className="young-head__tag focused" onClick={() => goToAllOnPeriodsWithTag("Tabou")}>#Tabou</button>
					</div>
				</div>
			</div>
			{isMobile && (		
				<div className="young-head__pref-button-container">
					<ChoosePreferenceButton />
				</div>
			)}
			
		</div>
	);
}

export default YoungHeader;
